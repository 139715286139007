.preview .item {
  position: relative;
  background: #1d1d1d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding: 100px 50px;
}

.preview .item .selected {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  border-radius: 0 0 15px 15px;
  background: #fff;
  color: #1d1d1d;
  width: auto;
}

.preview .item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(130deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.05));
  border-radius: 30px;
  opacity: 0.25;
}

.preview .img {
  padding: 40px;
  position: relative;
  z-index: 3;
}

.preview .img .screen {
  border: 1px solid #aaa;
  border-radius: 15px 15px 0 0;
  border-bottom: 0;
  overflow: hidden;
}

.preview .img .botm {
  width: calc(100% + 80px);
  margin-left: -40px;
  height: 30px;
  border: 1px solid #aaa;
  border-radius: 0 0 100px 100px;
}

.miwlo-app-screenshot-preview {
  position: relative;
}

.app-screenshot-mobile-frame {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: max-content;
  z-index: 2;
}

.miwlo-app-screenshot-wrap .swiper-container {
  width: 100%;
  padding-bottom: 30px;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1
}

.miwlo-app-screenshot-wrap .swiper-container-no-flexbox .swiper-slide {
  float: left
}

.miwlo-app-screenshot-wrap .swiper-container-vertical>.swiper-wrapper {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column
}

.miwlo-app-screenshot-wrap .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  box-sizing: content-box
}

.miwlo-app-screenshot-wrap .swiper-container-android .swiper-slide,
.miwlo-app-screenshot-wrap .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.miwlo-app-screenshot-wrap .swiper-container-multirow>.swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.miwlo-app-screenshot-wrap .swiper-container-free-mode>.swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto
}

.miwlo-app-screenshot-wrap .swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative
}

.miwlo-app-screenshot-wrap .swiper-3d .swiper-slide-shadow-left,
.miwlo-app-screenshot-wrap .swiper-3d .swiper-slide-shadow-right {
  background-image: none;
}

.miwlo-app-screenshot-wrap .swiper-slide img {
  display: block;
  margin: auto;
  width: 100%;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 4%);
}

.miwlo-app-screenshot-wrap .swiper-container-autoheight,
.miwlo-app-screenshot-wrap .swiper-container-autoheight .swiper-slide {
  height: auto
}

.miwlo-app-screenshot-wrap .swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform
}

.miwlo-app-screenshot-wrap .swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000
}

.miwlo-app-screenshot-wrap .swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y
}

.miwlo-app-screenshot-wrap .swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x
}

.miwlo-app-screenshot-wrap .swiper-container-3d {
  -webkit-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px
}

.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-cube-shadow,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-bottom,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-left,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-right,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-top,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d
}

.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-bottom,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-left,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-right,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10
}

.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-left,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-right,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-top,
.miwlo-app-screenshot-wrap .swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: none;
}

.miwlo-app-screenshot-wrap .swiper-container-coverflow .swiper-wrapper,
.miwlo-app-screenshot-wrap .swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px
}

.miwlo-app-screenshot-wrap .miwlo-app-screenshot-slide .swiper-slide {
  width: 250px;
  transform: unset !important;
}

.miwlo-app-screenshot-wrap .swiper-slide .slider-image .preview-icon {
  z-index: -1;
  width: calc(100% - 30px);
}

.miwlo-app-screenshot-wrap .swiper-slide.swiper-slide-active .slider-image:hover .preview-icon {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  z-index: 1;
}

.miwlo-app-screenshot-wrap .slider-image img {
  transform: scale(.9);
}

.miwlo-app-screenshot-wrap .swiper-slide-prev .slider-image img,
.miwlo-app-screenshot-wrap .swiper-slide-next .slider-image img {
  transform: scale(1);
}

.miwlo-app-screenshot-wrap .swiper-slide-prev .slider-image img {
  margin-left: -15px;
}

.miwlo-app-screenshot-wrap .swiper-slide-next .slider-image img {
  margin-right: -15px;
}

.miwlo-app-screenshot-wrap .swiper-slide-active .slider-image img {
  transform: scale(1.1);
  border-radius: 20px;
  -webkit-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  transition: all .3s ease-in;
}




.miwlo-app-screenshot-wrap .swiper-next,
.miwlo-app-screenshot-wrap .swiper-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #231f20;
  background-color: #231f20;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  color: #FFF;
  padding: 10px;
  z-index: 1;
  -webkit-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

.miwlo-app-screenshot-wrap .swiper-next:hover,
.miwlo-app-screenshot-wrap .swiper-prev:hover {
  background-color: #FFF;
  color: #231f20;
}

.miwlo-app-screenshot-wrap .swiper-next:focus,
.miwlo-app-screenshot-wrap .swiper-prev:focus {
  outline: 0;
}

@media (max-width: 575px) {
  .miwlo-app-screenshot-wrap {
    padding-top: 35px;
    padding-bottom: 105px;
  }

  .miwlo-app-screenshot-wrap .swiper-next,
  .miwlo-app-screenshot-wrap .swiper-prev {
    top: 107%;
  }

  .miwlo-app-screenshot-wrap .swiper-next {
    right: 35%;
  }

  .miwlo-app-screenshot-wrap .swiper-prev {
    left: 35%;
  }
}

.swiper-next {
  right: 0
}

.client-img {
  width: 300px; /* Ajuste o tamanho conforme necessário */
  height: auto; /* Para manter a proporção da imagem */
  filter: grayscale(80%);
  margin: 0 10px; /* Adiciona espaço entre as imagens */
  transition: filter 0.3s ease; /* Transição suave para hover */
}

.client-img:hover {
  filter: none; /* Remove o efeito de cinza quando o usuário passar o mouse */
}
.ac {
  align-items: center;
}